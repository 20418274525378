.App {
  text-align: center;
  background-color: white;
  color: black;
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 4%;
  padding-bottom: 3%;
  margin-bottom: 4%;
  border-radius: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  padding-top: 1%;
  min-height: 40vh;
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.instructions {
  color: black;
  width: 100%;
}

.instructions p {
  font-size: 16px;
}

.altrImg > a > img {
  margin-left: 1%;
  margin-top: 1%;
  position: absolute;
}

.altrImg {
  text-align: left;
}

.snippet {
  margin-left: 10%;
  margin-right: 10%;
  font-size: 14px;

  text-align: left;
  font-family: monospace;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
